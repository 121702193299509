import React from 'react'

const App = () => (
  <div className="App">
    Boa sorte!{' '}
    <span role="img" aria-label="foguete">
      🚀
    </span>
  </div>
)

export default App
